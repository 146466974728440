import React, { Suspense, useEffect } from "react";
import {
  HashRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Grid } from "@mui/material";

//pages imports
const NavigationBar = React.lazy(() =>
  import("./components/navigation/NavigationBar")
);
const HomePage = React.lazy(() => import("./pages/homepage/HomePage"));
const Footer = React.lazy(() => import("./components/navigation/Footer"));

function App() {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <HashRouter>
            <Suspense>
              <Routes>
                <Route path="/" element={<NavigationBar />}>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/footer" element={<Footer />} />
                </Route>
              </Routes>
            </Suspense>
          </HashRouter>
        </Grid>
      </Grid>
    </>
  );
}

export default App;
